<template>
  <div v-if="actionsMenu && actionsMenu.length > 0 && rowItem" class="i-actions-menu">
    <div
      ref="iActionsMenu"
      class="i-actions-menu-button"
      @mouseup.prevent="toggleMenu"
    >
      <font-awesome-icon
        class="bullets-icon"
        icon="fa-duotone fa-ellipsis-vertical"
        size="2x"
      />
    </div>

    <div
      ref="actionsMenuContainer"
      v-if="menuVisible"
      class="i-actions-menu-container"
      :style="{
            'top': offsetTop + 'px',
            'left': offsetLeft + 'px',
        }"
    >
      <div v-for="(item, index) in actionsMenu" :key="index">
        <div
          v-if="!item.displayAction || item.displayAction.call(this, rowItem)"
          class="i-actions-menu-item"
          @click="onMenuItemClick(item.action)"
        >
          <font-awesome-icon
            v-if="item.icon"
            class="action-icon"
            size="1x"
            :icon="item.icon"
          />

          <span v-if="item.name">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IActionsMenu',
  props: {
    actionsMenu: {
      type: Array,
    },
    rowItem: {
      type: Object
    },
  },
  data() {
    return {
      menuVisible: false,
      offsetTop: 0,
      offsetLeft: 0
    };
  },
  methods: {
    toggleMenu() {
      if (this.menuVisible) {
        this.menuVisible = false;
        return;
      }

      this.menuVisible = true;

      this.$nextTick(() => {
        this.calculateMenuPosition();
      });
    },

    calculateMenuPosition() {
      if (this.menuVisible) {
        let dropdownButtonMenu = this.$refs['iActionsMenu'];
        let dropdownMenu = this.$refs['actionsMenuContainer'];

        if (dropdownMenu && dropdownButtonMenu) {
          let sumHeight = dropdownButtonMenu.getBoundingClientRect().top + dropdownButtonMenu.offsetHeight + dropdownMenu.offsetHeight + 10 // 10px for before element;
          let sumWidth = dropdownButtonMenu.getBoundingClientRect().left + dropdownButtonMenu.offsetWidth + (dropdownButtonMenu.offsetWidth / 2)

          if (sumHeight > window.innerHeight) {
            this.offsetTop = dropdownButtonMenu.getBoundingClientRect().top - dropdownMenu.offsetHeight - 5;
          } else {
            this.offsetTop = dropdownButtonMenu.getBoundingClientRect().top + dropdownButtonMenu.offsetHeight + 5;
          }

          if (sumWidth > window.innerWidth) {
            this.offsetLeft = dropdownButtonMenu.getBoundingClientRect().left - dropdownMenu.offsetWidth + dropdownButtonMenu.offsetWidth;
          } else {
            this.offsetLeft = dropdownButtonMenu.getBoundingClientRect().left - dropdownMenu.offsetWidth + dropdownButtonMenu.offsetWidth;
          }
        }
      }
    },

    onMenuItemClick(action) {
      this.$emit('onMenuItemClick', {row: this.rowItem, action});
      this.menuVisible = false;
    },

    closeDropdown(e) {
      let el = this.$refs.iActionsMenu;
      let target = e.target;
      if (this.menuVisible && el !== undefined && el !== target && !el.contains(target)) {
        this.menuVisible = false;
      }
    },
  },

  created() {
    document.addEventListener('click', this.closeDropdown)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown)
  }
};
</script>

<style lang="scss">
@import "IActionsMenu";
</style>
