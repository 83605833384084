const iSharedDrivesConverter = (str, item) => {
  try {
    if ((item?.name === 'source_type' || item?.source_type === 'SHARED_DRIVE') && (str === 'Shared_drive' || str === 'SHARED_DRIVE')) {
      return 'Shared Drive'
    } else if (str === 'SHARED_DRIVE' || str === 'Shared_drive') {
      return 'Shared Drives'
    }

    return str

  } catch (e) {
    return str
  }
}

const iSharedFoldersConverter = (str, item) => {
  try {
    if ((item?.name === 'source_type' || item?.source_type === 'SHARED_FOLDER') && (str === 'Shared_folder' || str === 'SHARED_FOLDER')) {
      return 'Team Folder'
    } else if (str === 'SHARED_FOLDER' || str === 'Shared_folder') {
      return 'Team Folders'
    }

    return str

  } catch (e) {
    return str
  }
}

export {
  iSharedDrivesConverter,
  iSharedFoldersConverter,
}
