import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import LIST_TENANTS from '../../graphql/tenant/listTenants.gql';
import LIST_ONLY_TENANTS from '../../graphql/tenant/listOnlyTenants.gql';
import SEATS_SUMMARY from '../../graphql/tenant/seatsSummary.gql';
import ENABLE_TENANT_PROVIDER from '../../graphql/tenant/enableTenantProvider.gql';
import DISABLE_TENANT_PROVIDER from '../../graphql/tenant/disableTenantProvider.gql';

const list = ({pagination, filter}) => apolloClient
  .query({
    query: LIST_TENANTS,
    variables: {
      input: {
        pagination: {
          ...pagination,
          pageSize: parseInt(pagination.pageSize)
        },
        filter
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {tenants}}) => tenants)

const listOnlyTenants = ({pagination, filter}) => apolloClient
  .query({
    query: LIST_ONLY_TENANTS,
    variables: {
      input: {
        pagination: {
          ...pagination,
          pageSize: parseInt(pagination.pageSize)
        },
        filter
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {tenants}}) => tenants)

const getSeatsSummary = (dateFrom) => apolloClient
  .query({
    query: SEATS_SUMMARY,
    variables: {
      input: {
        dateFrom
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {data}}) => data)

const enableTenantProvider = (provider, userId) => apolloClient
  .query({
    query: ENABLE_TENANT_PROVIDER,
    variables: {
      input: {
        provider,
        userId
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {data}}) => data)

const disableTenantProvider = (provider, userId) => apolloClient
  .query({
    query: DISABLE_TENANT_PROVIDER,
    variables: {
      input: {
        provider,
        userId
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {data}}) => data)

export {
  list,
  listOnlyTenants,
  getSeatsSummary,
  enableTenantProvider,
  disableTenantProvider
}
