<template>
  <div class="i-dropdown" :class="{'disabled': disabled}">
    <div class="field-label">
      {{ label }}
    </div>

    <multiselect
      label="label"
      :class="{[textTransform]: true}"
      :value="selectedValue"
      :options="options"
      :placeholder="placeholder"
      :multiple="multiSelect"
      :disabled="disabled"
      :allow-empty="allowEmpty"
      :searchable="searchable"
      :taggable="taggable"
      @select="onSelect"
      @remove="onRemove"
      @tag="addTag"
    >
      <template v-slot:clear>
        <font-awesome-icon
          v-if="selectedValue && clearable"
          class="clear-btn btn-icon icon-clickable"
          icon="fa-duotone fa-xmark"
          @click.stop="clearAll()"
        />
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: "IDropdown",
  components: {Multiselect},
  props: {
    options: {
      type: Array
    },
    value: {},
    label: {
      type: String,
      default: 'Choose'
    },
    placeholder: {
      type: String,
      default: 'Choose'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    textTransform: {
      type: String,
      default: ''
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: null
    }
  },

  computed: {
    selectedValue() {
      if (this.multiSelect) {
        return this.options?.filter(option => this.value?.includes((option.value)))
      }

      return this.options?.find(option => option?.value === this.value)
    }
  },
  methods: {
    onSelect(data) {
      let dataValue
      if (this.multiSelect) {
        dataValue = [...this.value || [], data.value]
      } else {
        dataValue = data.value
      }
      this.$emit('input', dataValue)
    },

    clearAll() {
      this.$emit('input', null)
    },

    onRemove(data) {
      if (this.multiSelect) {
        let values = this.value.filter(val => data.value !== val)
        if (values.length === 0) {
          this.$emit('input', null)
        } else {
          this.$emit('input', values)
        }
      } else {
        this.$emit('input', null)
      }
    },
    addTag(newTag) {
      const tag = {
        label: newTag,
        value: newTag,
      }

      this.$emit('input', tag)
    },

  }
}
</script>

<style lang="scss">
@import "IDropdown";
</style>
