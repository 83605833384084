import Vue from 'vue'
import App from "@/App.vue";
import router from './router/app'
import store from './store/app'


import '@/boot/fontawesome';
import '@/boot/common';
import '@/boot/filters';
import '@/boot/vuelidate';
import '@/boot/notifications';
import '@/boot/hasAccessRights';
import '@/boot/hasProviderAccessRights';
import '@/boot/switchTheme';
import {getEllipsisStr} from "@/services/tableDataFormater";

import {iEmptyData} from '@/filter/iEmptyData'
import {iCapitalizeString} from '@/filter/iCapitalizeString'
import {iEnumConverter} from './filter/iEnumConverter'
import {iSharedDrivesConverter} from "@/filter/iSharedDrivesConverter";
import {iSharedFoldersConverter} from "@/filter/iSharedDrivesConverter";
import {iSecondsConverter} from "@/filter/iSecondsConverter";

Vue.config.productionTip = false

Vue.prototype.$hasListener = function $hasListener(listenerName) {
  let component = this;
  while (component) {
    if (component.$listeners[listenerName]) {
      return true;
    }
    component = component.$parent;
  }

  return false;
};


Vue.filter('iEmptyData', function (data, isFolder = false, emptyData = 'NA') {
  try {
    if (((Array.isArray(data) && !data.length) || data === null || data === 'null' || data === undefined || data === "undefined" || (!data.trim())) && isFolder) {
      return ''
    } else if (((Array.isArray(data) && !data.length) || data === null || data === 'null' || data === undefined || data === "undefined" || (!data.trim()))) {
      return emptyData
    }
    return data
  } catch (e) {
    return data
  }
});

Vue.filter('iEmptyDate', function (data, emptyData = 'NA') {

  try {
    if ((Array.isArray(data) && !data.length) || (!data.trim()) || data === null || data === 'null' || data === undefined || data === "undefined" || data <= '01-01-2020') {
      return emptyData
    }
    return data
  } catch (e) {
    return data
  }
});

Vue.filter('iCapitalizeString', function (str) {
  try {
    return str[0].toUpperCase() + str.slice(1).toLowerCase()
  } catch (e) {
    return str
  }

});

Vue.filter('iEllipsis', function (str, characterLength) {
  try {
    if (str) {
      return getEllipsisStr(str, 6, characterLength)
    }
  } catch (e) {
    return str
  }

});

Vue.filter('iSecondsConverter', function (totalSeconds) {
  try {
    return iSecondsConverter(totalSeconds)
  } catch (e) {
    return totalSeconds
  }

});

Vue.filter('iEnumConverter', iEnumConverter);

Vue.filter('iSharedDrivesConverter', iSharedDrivesConverter);

Vue.filter('iSharedFoldersConverter', iSharedFoldersConverter);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
