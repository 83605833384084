import {defaultPagination} from "@/services/pagination";
import {listBreadcrumps, listSnapshotDetails} from "@/services/api/app/google/recovery/gmail/data-set/mails";
import {exportSnapshot, restoreSnapshot} from "@/services/api/app/google/recovery/gmail/data-set/folders";
import {listInitialBackupStats} from "@/services/api/app/common/initialBackup";

const defaultState = () => ({
  files: [],
  searchFiles: [],
  searchPagination: {
    ...defaultPagination(),
    sortBy: 'snapshots.display_name',
    sortDescending: false
  },
  pagination: {
    ...defaultPagination(),
    sortBy: 'snapshots.display_name',
    sortDescending: false
  },
  folderId: null,
  breadcrumps: [],
  search: {
    searchTerm: ""
  },
  initialSearch: null,
  initialBackupStatus: {},
  filterBackupStatus: {
    provider: 2,
    module: 2,
    dataType: 2,
  },
})

export default {
  namespaced: true,

  actions: {
    listSnapshotDetails({commit, state}, data) {
      return listSnapshotDetails({
        ...data,
        pagination: state.pagination,
        folderId: state.folderId,
        searchTerm: data.searchTerm ? data.searchTerm : state?.search?.searchTerm
      })
        .then(({result, pagination}) => {
          let index = Date.parse(new Date());
          const res = result.map(file => {
            return {
              ...file,
              originalId: file.id,
              id: index++
            }
          })

          commit('setFiles', res)
          commit('setPagination', pagination)
        })
    },
    searchSnapshotDetails({commit}, data) {
      return listSnapshotDetails(data)
        .then(({result, pagination}) => {
          commit('setSearchFiles', result)
          commit('setSearchPagination', pagination)
          return {result, pagination}
        })
    },
    listBreadcrumps({state, commit}, data) {
      if (state.folderId && data.userId && data.snapshotDate) {
        return listBreadcrumps({
          ...data,
          folderId: state.folderId
        })
          .then(result => {
            commit('setBreadcrumps', result);
          })
      } else {
        commit('setBreadcrumps', []);
        return Promise.resolve();
      }

    },
    exportSnapshot({state, commit}, data) {
      return exportSnapshot(data)
    },
    restoreSnapshot({state, commit}, data) {
      return restoreSnapshot(data)
    },
    listInitialBackupStats({commit, state}, filter) {
      return listInitialBackupStats({
        ...state.filterBackupStatus,
        id: filter.id,
      })
        .then((result) => {
          commit('setInitialBackupStatus', result)
          return result;
        })
    },
  },

  mutations: {
    setFiles(state, files) {
      state.files = files;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setSearchFiles(state, files) {
      state.searchFiles = files;
    },
    setSearchPagination(state, pagination) {
      state.searchPagination = pagination;
    },
    resetSearchPagination(state) {
      state.searchPagination = {
        ...defaultPagination(),
        sortBy: 'snapshots.display_name',
        sortDescending: false
      };
    },
    setFolderId(state, folderId) {
      state.folderId = folderId;
    },
    setSearch(state, search) {
      state.search = search
    },
    setBreadcrumps(state, breadcrumps) {
      state.breadcrumps = breadcrumps;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
    setInitialSearch(state, initialSearch){
      state.initialSearch = initialSearch
    },
    setInitialBackupStatus(state, initialBackupStatus) {
      state.initialBackupStatus = initialBackupStatus
    },
  },

  state: defaultState()
}
