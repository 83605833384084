import {mapGetters} from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      providerLevelType: 'auth/providerLevelType',
    }),
  },
  methods: {
    hasProviderAccessRights(levelTypes) {
      if (!this.providerLevelType) {
        return true
      }

      return levelTypes.includes(this.providerLevelType)
    }
  }
}
