import {mapGetters} from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      accessLevel: 'auth/accessLevel',
    }),
  },
  methods: {
    hasAccessRights(accessRights) {
      return accessRights.includes(this.accessLevel)
    }
  }
}
