export default {
  data: () => ({
    currentTheme: localStorage.getItem("idrive-theme") || "default",
  }),
  methods: {
    switchTheme() {
      this.currentTheme = this.currentTheme === "default" ? "dark" : "default";
      localStorage.setItem("idrive-theme", this.currentTheme);

      window.location.reload();
    },
    handleStorageEvent(event) {
      if (event.key === "idrive-theme" && event.newValue) {
        this.currentTheme = event.newValue;
        window.location.reload();
      }
    }
  },
  created() {
    window.addEventListener("storage", this.handleStorageEvent);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.handleStorageEvent);
  },
};

