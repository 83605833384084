import {ApolloClient} from 'apollo-client'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {lazy} from 'apollo-link-lazy';

// const apolloLink  = createApolloLink(process.env.PROJECT);
// Create the apollo client
const apolloClient = new ApolloClient({
  link: lazy(() =>
  {
    return import('./apollo-mgmt.js');
  }),

  cache: new InMemoryCache({
    // addTypename: false
  })
});

export {
  apolloClient,
}

export default ({router, state}) => {
  // const isSsr = !!ssrContext;

  return apolloClient;
}
