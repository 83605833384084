const iSecondsConverter = (totalSeconds) => {
  try {
    const
      m = Math.floor(totalSeconds % 3600 / 60).toString().padStart(2, '0'),
      s = Math.floor(totalSeconds % 60).toString().padStart(2, '0');

    return `${m}:${s}`;

  } catch (e) {
    console.log(e)
    return totalSeconds
  }
}

export {
  iSecondsConverter,
}
